import React from "react"
import { css, Styled } from "theme-ui"

const Footer = () => (
  <footer
    css={css({
      mt: 4,
      pt: 3,
    })}
  >
    © {new Date().getFullYear()} {" "} • {" "} See also: <Styled.a href="https://twitter.com/tammosito">@tammosito</Styled.a>
  </footer>
)
export default Footer